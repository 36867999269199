"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoggedUserQuery = exports.LoggedUserDocument = exports.AddressFragmentAgainFragmentDoc = void 0;
const graphql_vue_apollo_patch_1 = require("@frk/graphql-vue-apollo-patch");
const vue_apollo_smart_ops_1 = require("vue-apollo-smart-ops");
exports.AddressFragmentAgainFragmentDoc = (0, graphql_vue_apollo_patch_1.gql) `
    fragment addressFragmentAgain on Address {
  city
  country
  latlng {
    lat
    lng
  }
  region
  value
}
    `;
exports.LoggedUserDocument = (0, graphql_vue_apollo_patch_1.gql) `
    query loggedUser {
  loggedUser {
    _id
    auth0Id
    avatar
    company {
      activity
      address {
        ...addressFragmentAgain
      }
      capital
      form
      name
      rcs
      siret
      tva
    }
    createdAt
    email
    firstname
    freelance {
      source
      expectedContract
      externalDocuments {
        id
        name
        url
        updatedAt
        createdAt
      }
      educations {
        id
        description
        institution
        name
        year
      }
      activity
      jobCategories
      geographicAvailability {
        address {
          ...addressFragmentAgain
        }
        mobility {
          ...addressFragmentAgain
        }
        houseMove {
          ...addressFragmentAgain
        }
      }
      birthday
      description
      location
      resume {
        url
        updatedAt
        parsedAt
      }
      timing
      keySkills {
        name
        description
      }
      status
      skills
      experiences {
        id
        actual
        description
        startDate
        endDate
        job
        location
        name
      }
      price {
        target
      }
      salary {
        target
      }
      marketSituation {
        isListening
        availableAt
        author {
          _id
          fullname
          avatar
        }
      }
      recommendations {
        id
        companyName
        contactEmail
        contactJobTitle
        contactName
        experienceEndDate
        experienceTitle
        experienceStartDate
        createdAt
        updatedAt
      }
    }
    fullname
    gender
    inboxAccounts {
      accountId
      provider
      status
    }
    lastname
    lastLoginAt
    linkedinUrl
    prospectToken
    referee {
      _id
      avatar
      email
      firstname
      lastname
      fullname
      tel
      title
    }
    roles
    signatory {
      gender
      email
      firstname
      lastname
      tel
      title
    }
    tel
    termsList
    title
    team {
      _id
      fullname
    }
  }
}
    ${exports.AddressFragmentAgainFragmentDoc}`;
/**
 * __useLoggedUserQuery__
 *
 * To use a Smart Query within a Vue component, call `useLoggedUserQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     loggedUser: useLoggedUserQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
exports.useLoggedUserQuery = (0, vue_apollo_smart_ops_1.createSmartQueryOptionsFunction)(exports.LoggedUserDocument);
