"use strict";
// * Welcome to the Boryl Tracking Plan
// ? We did not design this API and were asked to implement it as is
// ! The Boryl API Schema is questionable and is not in sync with our Nomenclature !
Object.defineProperty(exports, "__esModule", { value: true });
exports.borylPlans = void 0;
const helpers_1 = require("@frk/helpers");
const MarketSituation_core_1 = require("@frk/models-core/MarketSituation.core");
const Freelance_core_1 = require("@frk/models-core/Freelance.core");
const Mission_core_1 = require("@frk/models-core/Mission.core");
const utils_core_1 = require("@frk/models-core/utils.core");
function transformDate(date) {
    return helpers_1.dateUtils.formatDate(date, 'YYYY-MM-DD');
}
function transformFromOptions(options) {
    return function transform(value) {
        return (0, utils_core_1.getLabelByValue)(options, value);
    };
}
const seekFreelanceStep2 = {
    profile: 'firstMission.profile',
    skills: 'firstMission.skills',
};
const seekFreelanceStep3 = {
    seek_freelance_starting_date: {
        path: 'firstMission.startingDate',
        transform: transformDate,
    },
    seek_freelance_budget: 'firstMission.price',
    seek_freelance_duration: {
        path: 'firstMission.duration',
        transform: transformFromOptions(Mission_core_1.MISSION_DURATION_OPTIONS),
    },
};
const seekFreelanceForm = {
    seek_freelance_role: 'client.title',
};
const seekMissionStep2 = {
    profile: 'freelance.title',
    skills: 'freelance.skills',
};
const seekMissionStep3 = {
    seek_mission_status: {
        path: 'freelance.activity',
        transform: transformFromOptions(Freelance_core_1.ACTIVITY_OPTIONS),
    },
    seek_mission_availability_date: {
        path: 'freelance.marketSituation',
        transform: (marketSituation) => {
            if ((0, MarketSituation_core_1.isAvailableAtAsDate)(marketSituation.availableAt)) {
                return transformDate(helpers_1.dateUtils.createDate(marketSituation.availableAt).toDate());
            }
            return null;
        },
    },
    seek_mission_notice_period: {
        path: 'freelance.marketSituation',
        transform: (marketSituation) => {
            if ((0, MarketSituation_core_1.isAvailableAtAsPeriod)(marketSituation.availableAt)) {
                const transformFn = transformFromOptions(MarketSituation_core_1.PERIOD_OPTIONS);
                return transformFn(marketSituation.availableAt);
            }
            return null;
        },
    },
    seek_mission_price: 'freelance.price',
};
const seekMissionForm = {};
exports.borylPlans = {
    seek_freelance: [
        {
            event: 'funnel',
            event_category: 'funnel_seek_freelance',
            event_action: 'step1',
            event_label: 'description',
            event_name: 'funnel_seek_freelance_step1',
        },
        {
            event: 'funnel',
            event_category: 'funnel_seek_freelance',
            event_action: 'step2',
            event_label: 'availablity',
            event_name: 'funnel_seek_freelance_step2',
            ...seekFreelanceStep2,
        },
        {
            event: 'funnel',
            event_category: 'funnel_seek_freelance',
            event_action: 'step3',
            event_label: 'informations',
            event_name: 'funnel_seek_freelance_step3',
            ...seekFreelanceStep2,
            ...seekFreelanceStep3,
        },
        {
            event: 'form',
            event_category: 'form',
            event_action: 'submit',
            event_label: 'seek_freelance',
            event_name: 'form_seek_freelance_submitted',
            ...seekFreelanceStep2,
            ...seekFreelanceStep3,
            ...seekFreelanceForm,
        },
    ],
    seek_mission: [
        {
            event: 'funnel',
            event_category: 'funnel_seek_mission',
            event_action: 'step1',
            event_label: 'description',
            event_name: 'funnel_seek_description_step1',
        },
        {
            event: 'funnel',
            event_category: 'funnel_seek_mission',
            event_action: 'step2',
            event_label: 'situation',
            event_name: 'funnel_seek_mission_step2',
            ...seekMissionStep2,
        },
        {
            event: 'funnel',
            event_category: 'funnel_seek_mission',
            event_action: 'step3',
            event_label: 'informations',
            event_name: 'funnel_seek_mission_step3',
            ...seekMissionStep2,
            ...seekMissionStep3,
        },
        {
            event: 'form',
            event_category: 'form',
            event_action: 'submit',
            event_label: 'seek_mission',
            event_name: 'form_seek_mission_submitted',
            ...seekMissionStep2,
            ...seekMissionStep3,
            ...seekMissionForm,
        },
    ],
};
